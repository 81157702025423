import { FC, useState } from 'react';
import { ReactSVG } from 'react-svg';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Button, IExternalFillOwner, RemoveModal } from '@forma/forma-ui-kit';
import EditOrganizationModal from '../EditOrganizationModal';

import styles from './organizations-list.module.css';

interface OrganizationsListProps {
  items?: IExternalFillOwner[],
  selected: string|null,
  onSelect: (id: string|null) => void,
  onRemove?: (id: string) => void,
  showInn?: boolean,
  showAddress?: boolean
}

const OrganizationsList: FC<OrganizationsListProps> = ({
  items, selected, onSelect, onRemove, showAddress, showInn
}) => {
  const { t } = useTranslation();
  const [ isEdit, setIsEdit ] = useState<boolean>(false);
  const [ editingId, setEditingId ] = useState<string|null>(null);

  const editingOwner = items?.find(({ id }) => id === editingId);

  return (
    <div className={styles.root}>
      {items?.map(({ id, name, inn, address }) => (
        <div className={classNames(styles.owner, selected === id && styles.active)} key={id}>
          <div className={styles.ownerCheckbox} onClick={() => onSelect(id)}>
            <ReactSVG src="/icons/checked.svg" wrapper="span" />
          </div>
          <div className={styles.ownerName} onClick={() => onSelect(id)}>
            <div><span>{t('organization_name')}:</span> {name}</div>
            {showInn && <div><span>{t('inn')}:</span> {inn}</div>}
            {showAddress && <div><span>{t('address')}:</span> {address}</div>}
          </div>
          <div className={styles.ownerButtons}>
            <Button
              viewStyle="text"
              icon={<ReactSVG src="/icons/edit.svg" />}
              title={t('edit')}
              onClick={() => {
                setEditingId(id);
                setIsEdit(true);
              }}
            />
            {onRemove && (
              <RemoveModal
                title={t('deleting_organization')}
                control={
                  <Button
                    title={t('delete')}
                    className={styles.remove}
                    viewStyle="text"
                    icon={<ReactSVG src="/icons/trash.svg" wrapper="span" />}
                  />
                }
                onRemove={() => onRemove(id)}
                message={t('do_yo_want_delete_organization')}
              />
            )}
          </div>
        </div>
      ))}
      <div className={styles.ownersButtons}>
        <Button
          viewStyle="tertiary"
          size="small"
          onClick={() => setIsEdit(true)}
        >
          {t('add_organization')}
        </Button>
      </div>

      <EditOrganizationModal
        open={isEdit}
        onClose={() => {
          setIsEdit(false);
          setEditingId(null);
        }}
        data={editingOwner}
        onSubmit={onSelect}
      />
    </div>
  );
};

export default OrganizationsList;

import { FC, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import { useDispatch } from 'react-redux';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { ButtonsContainer, LoadingButton, Modal, nanoid } from '@forma/forma-ui-kit';
import FillDocument from 'views/FillDocument';
import SendExternalFillModal from 'views/ExternalFill/SendExternalFillModal';
import webView from 'helpers/webview';

import { ITemplateRenderData, useGetTemplateSidesQuery, useRenderTemplateMutation } from 'store/templates/templatesApi';
import { useDuplicateExternalFillMutation, useGetExternalFillByIdQuery, useLazyDownloadExternalFillAttachmentsQuery, useSendExternalFillMutation } from 'store/externalfill/externalfillApi';
import { addNotification } from 'store/notifications/notificationsSlice';
import { ISidesListEditData, ITemplateSide, TFormatDocument } from 'interfaces/templates.interface';

import styles from './ExternalFillModal.module.css';


interface ExternalFillModalProps {
  open: boolean,
  onClose?: () => void,
  id: string,
  name: string
}

const InfoItem: FC<{ label: string, value?: string }> = ({ label, value }) => {
  return (
    <div className={styles.infoItem}>
      <div className={styles.infoLabel}>{label}:</div>
      <div className={styles.infoValue}>{value}</div>
    </div>
  );
};

const ExternalFillModal: FC<ExternalFillModalProps> = ({
  open, onClose, id, name
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { data: externalFill } = useGetExternalFillByIdQuery(id, { skip: !open });
  // const { id, name, createdAt, readAt, filledAt, templateId, sideValues, attachments, isReady, urlKey } = data;
  const { data: templateSides } = useGetTemplateSidesQuery(externalFill?.templateId ?? '', { skip: !externalFill?.templateId || !open });
  const [ duplicateExternalFill ] = useDuplicateExternalFillMutation();
  const [ downloadExternalFillAttachments, { isLoading: isAttachmentsLoading } ] = useLazyDownloadExternalFillAttachmentsQuery();
  const [ renderTemplate, { isLoading: isDownloading } ] = useRenderTemplateMutation();
  const [ sendExternalFill ] = useSendExternalFillMutation();

  const [ isShowSend, setShowSend ] = useState<boolean>(false);
  const [ isFillingValid, setIsFillingValid ] = useState<boolean>(false);
  const [ sidesData, setSidesData ] = useState<ITemplateRenderData['sideValues']|undefined>(externalFill?.sideValues);

  useEffect(() => {
    if (open) window?.ReactNativeWebView?.postMessage('documentIsOpen');
    else window?.ReactNativeWebView?.postMessage('documentClosed');
  }, [open]);

  const handleClickRender = (
    name: string,
    sideValues: ITemplateRenderData['sideValues'],
    format: TFormatDocument
  ) => {
    if (!externalFill) return null;
    if (window?.navigator?.userAgent === 'forma-mobile') {
      webView.renderTemplate(externalFill.templateId, `${name}.${format}`, { name, sideValues, format });
    } else {
      renderTemplate({ id: externalFill.templateId, name, sideValues, format: format }).unwrap().then(url => {
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = `${name}.${format}`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      }).catch(e => {});
    }
  };

  const handleClickDuplicate = async () => {
    const res = await duplicateExternalFill({ id });
    if ('data' in res) {
      dispatch(addNotification({ content: t('external_fill_created'), type: 'SUCCESS' }));
      localStorage.setItem('externalFillSendingId', res.data.id);
      navigate('/documents');
      if (onClose) onClose();
    }
  };

  const handleClickDownload = () => {
    if (!externalFill) return null;
    if (window?.navigator?.userAgent === 'forma-mobile') {
      webView.externalFillAttachemnts(id, `${externalFill.name}.zip`);
      if (onClose) onClose();
    } else {
      downloadExternalFillAttachments(id).unwrap().then(url => {
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = `${externalFill.name}.zip`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        if (onClose) onClose();
      }).catch(e => {});
    }
  };

  const handleClickSend = async (id: string, data: { login: string }) => {
    setShowSend(false);
    const res = await sendExternalFill({ id, email: data.login });
    if ('data' in res) {
      dispatch(addNotification({ content: t('email_sended_to_your_counteragent'), type: 'SUCCESS' }));
    }
  };

  const { sides, valueSides } = useMemo(() => {
    if (!externalFill || !templateSides) return { sides: undefined, valueSides: undefined };

    const sides: ITemplateSide[] = [];
    const valueSides: ISidesListEditData = {};
    for (let i=0; i < templateSides.sides.length; i++) {
      const side = templateSides.sides[i];
      const id = side.id ?? i+1+'';
      sides.push({ ...side, id });

      const sideValues = externalFill.sideValues?.[i] ?? {};
      valueSides[id] = {
        ...sideValues,
        id,
        tableValues: sideValues.tableValues?.length > 0 ? (
          sideValues.tableValues.map(({ products, ...table }) => ({
            ...table,
            products: products.map(product => ({ id: nanoid(8), ...product })),
            customProductsIds: []
          }))
        ) : [],
        tattrValues: sideValues.tattrValues ?? {},
        tattrErrors: {},
        // isValid: true,
      };
    }

    return { sides, valueSides };
  }, [externalFill, templateSides]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={name}
      width="100%"
      maxWidth="1150px"
    >
      <div className={styles.root}>
        {externalFill && (
          <div className={styles.info}>
            <InfoItem label={t('created')} value={format(new Date(externalFill.createdAt), 'dd.MM.yyyy HH:mm')} />
            <InfoItem label={t('opened')} value={externalFill.readAt && format(new Date(externalFill.readAt), 'dd.MM.yyyy HH:mm')} />
            <InfoItem label={t('filled_and_sended')} value={externalFill.filledAt && format(new Date(externalFill.filledAt), 'dd.MM.yyyy HH:mm')} />
          </div>
        )}
        <ButtonsContainer className={styles.buttons}>
          <LoadingButton
            type="submit"
            className={styles.button}
            viewStyle="tertiary"
            size="small"
            onClick={() => (externalFill && sidesData) && handleClickRender(externalFill.name, sidesData, 'pdf')}
            disabled={!isFillingValid}
            isLoading={isDownloading}
            icon={<ReactSVG src="/icons/download.svg" />}
          >
            {t('download_pdf')}
          </LoadingButton>
          <LoadingButton
            type="submit"
            className={styles.button}
            viewStyle="tertiary"
            size="small"
            onClick={() => (externalFill && sidesData) && handleClickRender(externalFill.name, sidesData, 'docx')}
            disabled={!isFillingValid}
            isLoading={isDownloading}
            icon={<ReactSVG src="/icons/download.svg" />}
          >
            {t('download_docx')}
          </LoadingButton>
          <LoadingButton
            viewStyle="tertiary"
            size="small"
            className={styles.button}
            icon={<ReactSVG src="/icons/download-file.svg" />}
            onClick={handleClickDownload}
            disabled={!externalFill?.attachments.length || !externalFill.attachments.every(({ size }) => !!size)}
            isLoading={isAttachmentsLoading}
          >
            {t('download_attachments')}
          </LoadingButton>
          <LoadingButton
            viewStyle="tertiary"
            size="small"
            className={styles.button}
            icon={<ReactSVG src="/icons/copy.svg" />}
            onClick={handleClickDuplicate}
            disabled={!!externalFill?.isReady}
          >
            {t('duplicate_to_filling')}
          </LoadingButton>
          <LoadingButton
            viewStyle="primary"
            size="small"
            className={styles.button}
            onClick={() => setShowSend(true)}
            disabled={!!externalFill?.isReady}
          >
            {t('send_to_fiiling')}
          </LoadingButton>
        </ButtonsContainer>

        {sides && (
          <FillDocument
            className={styles.filling}
            name={name}
            sides={sides}
            onClickDownload={handleClickRender}
            isDownloadLoading={isDownloading}
            valueSides={valueSides}
            showFillMessage={false}
            setIsFillingValid={setIsFillingValid}
            setSidesDataForInnerRenderButton={setSidesData}
            showButtons={false}
            showHead={false}
          />
        )}
      </div>

      {externalFill && (
        <SendExternalFillModal
          open={isShowSend}
          onClose={() => setShowSend(false)}
          onSubmit={data => handleClickSend(id, data)}
          urlKey={externalFill.urlKey}
        />
      )}
    </Modal>
  );
};

export default ExternalFillModal;
